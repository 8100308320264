import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLazyLoad from "vue-lazyload";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import "./plugins/moment";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import ApexCharts from "apexcharts";
import VueApexCharts from "vue-apexcharts";
import FlipCountdown from "vue2-flip-countdown";
import VuePdfApp from "vue-pdf-app";

const request = axios.create({

  baseURL: "https://hrvec.ovec.go.th/HRvec_api/"

/*       baseURL: 'http://localhost:8080/HRvec_api/',
 */  


});
Vue.config.productionTip = false;
Vue.use(VueAxios, request);
Vue.use(VueLazyLoad);
Vue.use(VueApexCharts);
Vue.use(FlipCountdown);

Vue.component("vue-pdf-app", VuePdfApp);
Vue.component("apexchart", VueApexCharts);
Vue.component("FlipCountdown");

new Vue({
  router,
  store,
  vuetify,
  i18n,
  FlipCountdown,
  render: h => h(App)
}).$mount("#app");
